<template>
  <div class="grouplist-wrap">
    <div class="bread-wrap">
      <span class="bread-title">返利数据汇总</span>
      <!-- <download-excel
              class="export-excel-wrapper"
              v-if="tabledata&&tabledata.length!=0"
              :data="tabledata"
              :fields="json_fields"
              name="本账号客户资源汇总.xls"
            >
    <el-button type="text"  v-if="tabledata&&tabledata.length!=0">导出excel表格</el-button>
    </download-excel> -->
    </div>
    <div class="content">
      <div>
        <span>月份：</span>
        <el-date-picker
          v-model="month"
          type="month"
          placeholder="选择月份"
          value-format="yyyy-MM"
          size="small"
        />
        <el-button
          class="filter-item"
          type="primary"
          size="small"
          @click="handleSearch"
        >
          确认
        </el-button>
        <el-button type="primary" size="small" @click="exportTable"
          >导出</el-button
        >
        <!-- <el-button type="primary"
                   style="margin-left: 16px"
                   @click="drawer = true">
          open
        </el-button> -->
      </div>
      <el-divider />
      <el-descriptions border class="summary" size="small" :column="3">
        <el-descriptions-item label="总消费金额(元)" width="16.7%">{{
          (totalConsumption / 100).toFixed(2)
        }}</el-descriptions-item>
        <el-descriptions-item label="总现金消费金额(元)" width="16.7%">{{
          (totalCashConsumption / 100).toFixed(2)
        }}</el-descriptions-item>
        <el-descriptions-item label="总赠金消费金额(元)" width="16.7%">{{
          (totalBonusConsumption / 100).toFixed(2)
        }}</el-descriptions-item>
        <el-descriptions-item
          v-if="channelType != '2'"
          label="总用户现金支出(元)"
          width="16.7%"
          >{{
            ((totalRecharge + totalCashBuy + totalCashRenew) / 100).toFixed(2)
          }}</el-descriptions-item
        >
        <el-descriptions-item
          v-if="channelType != '2'"
          label="总赠金金额(元)"
          width="16.7%"
          >{{ (totalBonus / 100).toFixed(2) }}</el-descriptions-item
        >
        <el-descriptions-item
          v-if="channelType != '2'"
          label="总现金消费返利金额"
          width="16.7%"
          >{{ (totalConsumptionRebate / 100).toFixed(2) }}</el-descriptions-item
        >
        <el-descriptions-item
          v-if="channelType != '2'"
          label="总赠金成本"
          width="16.7%"
          >{{ (totalBonusCost / 100).toFixed(2) }}</el-descriptions-item
        >
        <el-descriptions-item
          v-if="channelType != '2'"
          label="云手机结算折扣"
          width="16.7%"
          >{{ phoneGross }}</el-descriptions-item
        >
        <el-descriptions-item
          v-if="channelType != '2'"
          label="直播快结算折扣"
          width="16.7%"
          >{{ pathGross }}</el-descriptions-item
        >
        <el-descriptions-item label="总返利(元)" width="16.7%">{{
          (rebate / 100).toFixed(2)
        }}</el-descriptions-item>
      </el-descriptions>

      <el-tabs v-model="activeTab">
        <el-tab-pane label="资源消费" name="consumption">
          <div class="table-wrapper">
            <el-table
              class="export-table"
              v-loading="loading"
              :data="list"
              row-key="resourceId"
              stripe
              height="calc(100vh - 450px)"
              table-layout="fixed"
            >
              <el-table-column prop="ResourceId" label="资源ID" />
              <el-table-column prop="UserId" label="用户ID" />
              <el-table-column prop="ChannelOuterId" label="关联ID" />
              <el-table-column prop="ResourceType" label="资源类型" />
              <el-table-column
                v-if="channelType != '2'"
                prop="Discount"
                label="折扣"
              />
              <el-table-column
                v-if="channelType == '2'"
                prop="Discount"
                label="返利比例"
              />
              <el-table-column
                prop="Consumption"
                label="月内消费金额(元)"
                :formatter="fentoyuan"
              />
              <el-table-column
                prop="CashConsumption"
                label="月内现金消费金额(元)"
                :formatter="fentoyuan"
              />
              <el-table-column
                prop="BonusConsumption"
                label="月内赠金消费金额(元)"
                :formatter="fentoyuan"
              />
              <el-table-column
                prop="ConsumptionRebate"
                label="现金消费返利金额(元)"
                :formatter="fentoyuan"
              />
              <el-table-column
                v-if="channelType != '2'"
                prop="BonusCost"
                label="赠金成本(元)"
                :formatter="fentoyuan"
              />
              <el-table-column
                v-if="channelType != '2'"
                prop="rebate"
                label="实际返利(元)"
              >
                <template #default="scope">
                  {{
                    (
                      (scope.row.ConsumptionRebate - scope.row.BonusCost) /
                      100
                    ).toFixed(2)
                  }}
                </template>
              </el-table-column>

              <el-table-column
                v-if="channelType == '2'"
                prop="CountTime"
                label="首单返利月份"
                :formatter="tableUnitMonthTimeFormatter"
              />
              <el-table-column
                prop="CreateTime"
                label="创建时间"
                :formatter="tableUnitTimeFormatter"
              />
              <el-table-column
                prop="ExpireTime"
                label="过期时间"
                :formatter="tableUnitTimeFormatter"
              />
              <el-table-column prop="Inviter" label="邀请人" />
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="赠金发放" name="bonus">
          <div class="table-wrapper">
            <el-table
              class="export-table-2"
              v-loading="loading"
              :data="list2"
              row-key="userId"
              stripe
              height="calc(100vh - 450px)"
              table-layout="fixed"
            >
              <el-table-column prop="UserId" label="用户ID" />
              <el-table-column
                prop="Bonus"
                label="赠金金额(元)"
                :formatter="fentoyuan"
              />
              <el-table-column
                prop="CreateTime"
                label="发放时间"
                :formatter="tableUnitTimeFormatter"
              />
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="用户现金支出" name="cash">
          <div class="table-wrapper">
            <el-table
              class="export-table-3"
              v-loading="loading"
              :data="list3"
              row-key="userId"
              stripe
              height="calc(100vh - 450px)"
              table-layout="fixed"
            >
              <el-table-column prop="UserId" label="用户ID" />
              <el-table-column prop="OrderType" label="订单类型" />
              <el-table-column prop="PayType" label="支付方式" />
              <el-table-column
                prop="Total"
                label="金额(元)"
                :formatter="fentoyuan"
              />
              <el-table-column
                prop="CreateTime"
                label="时间"
                :formatter="tableUnitTimeFormatter"
              />
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane v-if="!subAccount" label="子账号业绩统计" name="saler">
          <div class="table-wrapper">
            <el-table
              class="export-table-4"
              v-loading="loading"
              :data="list4"
              row-key="SalerId"
              stripe
              height="calc(100vh - 450px)"
              table-layout="fixed"
            >
              <el-table-column prop="SalerId" label="销售ID" />
              <el-table-column prop="SalerName" label="销售名" />
              <el-table-column
                prop="CashConsumption"
                label="总现金消费(元)"
                :formatter="fentoyuan"
              />
              <el-table-column
                prop="BonusConsumption"
                label="总赠金消费(元)"
                :formatter="fentoyuan"
              />
              <el-table-column
                prop="ConsumptionRebate"
                label="现金消费返利(元)"
                :formatter="fentoyuan"
              />
              <el-table-column
                v-if="channelType != '2'"
                prop="BonusCost"
                label="赠金成本(元)"
                :formatter="fentoyuan"
              />
              <el-table-column prop="rebate" label="实际返利(元)">
                <template #default="scope">
                  {{
                    (
                      (scope.row.ConsumptionRebate - scope.row.BonusCost) /
                      100
                    ).toFixed(2)
                  }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { utils as xutils, write } from "xlsx";
import { saveAs } from "file-saver";
import formatdate from "@/utils/format";

export default {
  name: "channelRebates",
  data() {
    return {
      loading: false,
      month: "",
      totalConsumption: 0,
      totalCashConsumption: 0,
      totalBonusConsumption: 0,
      totalBonus: 0,
      totalConsumptionRebate: 0,
      totalBonusCost: 0,
      rebate: 0,
      totalRecharge: 0,
      totalCashBuy: 0,
      totalCashRenew: 0,
      list: [],
      list2: [],
      list3: [],
      list4: [],
      phoneGross: 0.0,
      pathGross: 0.0,
      activeTab: "consumption",
      hasData: false,
      drawer: false,
      subAccount: true,
      channelType: 1,
    };
  },
  mounted() {
    this.$store.dispatch("user/getuserinfo").then((res) => {
      if (res.RetCode == 0) {
        this.subAccount = res.SubAccount;
        this.channelType = res.ChannelType;
      }
    });
  },
  methods: {
    tableUnitTimeFormatter: (row, column, cellValue, index) => {
      if (cellValue == 0) {
        return "";
      }
      return new Date(cellValue * 1000).toLocaleDateString();
    },
    tableUnitMonthTimeFormatter: (row, column, cellValue, index) => {
      if (cellValue == 0) {
        return "";
      }
      return formatdate(cellValue, "YYYY/MM");

    },
    fentoyuan: (row, column, cellValue, index) => {
      return (cellValue / 100).toFixed(2);
    },
    handleSearch() {
      if (this.month == "") {
        this.$message.error("请选择月份");
      }
      console.log(this.month);
      if (this.channelType == 2) {
        this.$store
          .dispatch("user/channelrebatesb", { Month: this.month })
          .then((res) => {
            this.list = res.Consumption;
            this.list2 = res.Bonus;
            this.list3 = res.Payment;
            this.list4 = res.Saler;
            this.totalConsumption = res.TotalConsumption;
            this.totalCashConsumption = res.TotalCashConsumption;
            this.totalBonusConsumption = res.TotalBonusConsumption;
            this.totalBonus = res.TotalBonus;
            this.totalConsumptionRebate = res.TotalConsumptionRebate;
            this.totalBonusCost = res.TotalBonusCost;
            this.rebate = res.Rebate;
            this.phoneGross = res.PhoneGross;
            this.pathGross = res.PathGross;
            this.totalRecharge = res.TotalRecharge;
            this.totalCashBuy = res.TotalCashBuy;
            this.totalCashRenew = res.TotalCashRenew;
            this.hasData = true;
          });
      } else {
        this.$store
          .dispatch("user/channelrebates", { Month: this.month })
          .then((res) => {
            this.list = res.Consumption;
            this.list2 = res.Bonus;
            this.list3 = res.Payment;
            this.list4 = res.Saler;
            this.totalConsumption = res.TotalConsumption;
            this.totalCashConsumption = res.TotalCashConsumption;
            this.totalBonusConsumption = res.TotalBonusConsumption;
            this.totalBonus = res.TotalBonus;
            this.totalConsumptionRebate = res.TotalConsumptionRebate;
            this.totalBonusCost = res.TotalBonusCost;
            this.rebate = res.Rebate;
            this.phoneGross = res.PhoneGross;
            this.pathGross = res.PathGross;
            this.totalRecharge = res.TotalRecharge;
            this.totalCashBuy = res.TotalCashBuy;
            this.totalCashRenew = res.TotalCashRenew;
            this.hasData = true;
          });
      }
    },
    exportTable() {
      if (!this.hasData) {
        alert("请先获取数据");
        return;
      }
      var sh1 = xutils.table_to_sheet(document.querySelector(".export-table"), {
        raw: true,
      });
      var sh2 = xutils.table_to_sheet(
        document.querySelector(".export-table-2"),
        { raw: true }
      );
      var sh3 = xutils.table_to_sheet(
        document.querySelector(".export-table-3"),
        { raw: true }
      );
      var sh4 = xutils.table_to_sheet(
        document.querySelector(".export-table-4"),
        { raw: true }
      );
      var wb = xutils.book_new();
      xutils.book_append_sheet(wb, sh1, "资源消费");
      xutils.book_append_sheet(wb, sh2, "赠金发放");
      xutils.book_append_sheet(wb, sh3, "用户现金支出");
      if (!this.subAccount) {
        xutils.book_append_sheet(wb, sh4, "销售业绩");
      }
      var wbout = write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "binary",
      });
      // 将字符串转ArrayBuffer
      function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      }
      try {
        saveAs(
          new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
          "返利数据汇总-" + this.month + ".xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      return wbout;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>